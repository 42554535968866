<script setup lang="ts">
import {
  FieldRequiredLevel,
  FieldUsage,
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'
import type {
  IManagerFieldEmits,
  IManagerFieldProps,
  IManagerFieldSlots,
} from './types'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerFieldEmits>()

defineSlots<IManagerFieldSlots>()
const slots = useSlots()

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  disabled,
  min,
  max,
  handleChange,
  handleBlur,
} = useField(props, emit)

const { castPlugin } = useFieldCast(props.node)

// Initialize calculated value, after setting default value
const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

// Initialize data field, after setting default value
const { registerData } = useFieldData(props.node, parentValue)
registerData()

useFieldCache(props.node, parentValue, props.preserveCache)
</script>

<template>
  <ManagerSharedFieldBase
    :path="path"
    :col-size="colSize"
    :tooltip="node.tooltip"
    :help="help"
    :error-message="errorMessage"
    :usage="node.usage"
  >
    <FormKit
      :id="id"
      v-model="modelValue"
      :type="type"
      :name="node.name"
      :label="label"
      label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
      :plugins="[castPlugin]"
      outer-class="w-full "
      :min="min"
      :max="max"
      :disabled="disabled"
      @input="handleChange"
      @blur="handleBlur"
    >
      <template v-if="node.prefix" #prefix>
        <span
          class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
        >
          {{ node.prefix }}
        </span>
      </template>
      <template v-if="node.suffix" #suffix>
        <span
          class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
        >
          {{ node.suffix }}
        </span>
      </template>
    </FormKit>
  </ManagerSharedFieldBase>
</template>
